<template>
    <div class="card">
        <div v-if="!loading && selectedTopic">
            <DataTable 
                :value="showIsProgramFilteredByCompany()" 
                :loading="loading" 
                responsiveLayout="scroll" 
                showGridlines
                :paginator="true" 
                
                :rows="rowsPagValue" 
                :rowsPerPageOptions="[ 5,10, 20, 50]" 
                :rowHover="true" 
                :first="firstPagValue"
                
                removableSort
                dataKey="documentProgramId"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
                v-model:filters="filters"
                filterDisplay="menu"
                :sort-field="sortFieldValue" 
                :sort-order="sortOrderValue" 
                @sort="onSort($event)"
                @page="onSort($event)"
            >
                <template #header>
                    <div style="
                        display: flex; 
                        justify-content: space-between;
                    ">
                        <div style="display: flex; justify-content: center; align-items: center;">
                            <h5 style="margin-bottom: 0">
                                <!-- Programs for: <strong> {{ selectedTopic.name }} </strong> -->
                                Programmes
                            </h5>
                            <Button
                                class="p-button-raised p-button-rounded my-3 p-button-help mx-3 " 
                                icon="pi pi-question-circle" 
                                @click="goToHeaderPage('programOverview-header')"
                            />
                        </div>

                        <div class="mb-2 md:m-0 p-as-md-center" >
                            <!-- if we add slot check  -->
                            <!-- <label> 
                                show all <strong> Companies Program</strong>
                            </label>
                            <InputSwitch v-model="showCompany" style="margin-left: 0.7rem;"/> -->
                        </div>
                    </div>

                    
                    
                    <div class="table-header row gy-3 d-flex flex-row justify-content-between">
                        <!-- add Button -->
                        <div class="col-sm-12 col-md-4 d-flex flex-row justify-content-md-start">
                            <Button 
                                type="button"
                                icon="pi pi-plus"
                                label="Add Programme"
                                class="p-button-outlined"
                                @click="openCreateProgram()" 
                            />
                        </div>
                        <!-- Clear Button, Filters , keywordSearch  -->
                        <div class="col-sm-12 col-md-8">
                            <div class="d-flex flex-row justify-content-md-end">
                                <Button 
                                    type="button" 
                                    icon="pi pi-filter-slash" 
                                    label="Clear"
                                    class="p-button-outlined me-4" 
                                    @click="clearFilter()" 
                                />
                                <Dropdown
                                    v-model="filterProgramStatus"
                                    :options="programStatusOptions"
                                    optionLabel="status"
                                    optionGroupLabel="label"
                                    optionGroupChildren = "items"
                                    optionValue="status"
                                    placeholder="Filter Status"
                                    :showClear="true"
                                    class="me-4"
                                    style="width: 25%;"
                                >
                                <template #optiongroup="slotProps">
                                    <div v-if="!slotProps.option.label" class="flex align-items-center">
                                        <hr>
                                    </div>
                                    <div v-else>
                                    </div>
                                </template>

                                </Dropdown>

                                <span class="p-input-icon-left" style="position: relative;">
                                    <i class="pi pi-search" style="position: absolute; left: 5%; top:50%" />
                                    <InputText 
                                        v-model="filters['global'].value" 
                                        placeholder="Keyword Search" 
                                        class="keywordSearch" 
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </template>
                <!-- ProgramName -->
                <Column 
                    :field="'name'" 
                    :header="'Title'" 
                    :key="'name'" 
                    :sortable="true"
                >
                    <template #body="slotProps">
                        <div 
                            @click="openEditProgram(slotProps.data)"
                            :style="{ width: '100%', height: '100%', cursor: 'pointer' }">
                            {{ slotProps.data.name }}
                        </div>
                    </template>
                </Column>

                <!-- Date Added -->
                <Column
                    :header="'Date Updated'"
                    :field="'dateAdd'"
                    :sortable="true"
                >
                    <template #body="slotProps">
                        <div>
                            {{ formatDate(slotProps.data.documentProgramTopics[0].programDate) }}
                        </div>
                    </template>
                </Column>
                <!-- segmentations -->
                <div 
                    v-for="(segmentation) in selectedTopic.segmentations.sort((a,b)=> a.order - b.order)" 
                    :key="segmentation.segmentationId"
                >
                    <div v-if="segmentation.isRegional">
                        <Column
                            :header="'Country'"
                            :key="segmentation.segmentationId"
                        >
                            <template #body="slotProps">
                                <div 
                                    v-if ="slotProps.data.documentProgramTopics[0].docProgramTopicSegItems.filter(docProgramTopicSegItem => docProgramTopicSegItem.segmentationItem.segmentationId === segmentation.segmentationId )[0]"
                                >
                                    {{getCountryName(slotProps.data.documentProgramTopics[0].docProgramTopicSegItems.filter(docProgramTopicSegItem => docProgramTopicSegItem.segmentationItem.segmentationId === segmentation.segmentationId )[0].regionCountry.countryId)  }}
                                </div>
                                <div v-else style="color: red;">
                                    segmentationItem Not Found
                                </div>
                            </template>
                        </Column>
                    </div>
                    <div v-else>
                        <Column
                            :header="segmentation.name"
                            :key="segmentation.segmentationId"
                        >
                            <template #body="slotProps"> 
                                <div>
                                    <div v-if="slotProps.data.documentProgramTopics[0].docProgramTopicSegItems.filter(docProgramTopicSegItem => docProgramTopicSegItem.segmentationItem.segmentationId === segmentation.segmentationId )[0]">
                                        {{ slotProps.data.documentProgramTopics[0].docProgramTopicSegItems.filter(docProgramTopicSegItem => docProgramTopicSegItem.segmentationItem.segmentationId === segmentation.segmentationId )[0].segmentationItem.name  }}
                                    </div>
                                    <div v-else style="color: red;">
                                        segmentationItem Not Found
                                    </div>
                                </div>
                            </template>
                        </Column>
                    </div>
                    
                </div>
                <!-- DocumentCount -->
                <Column
                    :header="'Document count'"
                    :key="'Document count'"
                    bodyStyle="text-align: right"
                    style="width: 5rem;"
                >
                    <template #body="slotProps">
                        {{slotProps.data.totalDocuments }}
                    </template>
                </Column>

                <!-- Status  -->
                <Column
                    :header="'Status'"
                    :field="'status'"
                    :sortable="true"
                >
                    <!-- <template #body="slotProps">
                        <div>
                            {{ getProgramStatusName(slotProps.data.documentProgramStatusId)  }}
                        </div>
                    </template> -->
                </Column>

                <!-- checkbox -->
                <Column
                    :key="'documentProgramId'"
                    style="display: flex;
                        justify-content: center;
                    "
                >
                    <template #body="slotProps">
                        <Checkbox 
                            v-model="programCheck" 
                            :inputId="slotProps.data.documentProgramTopics[0].documentProgramTopicId" 
                            :value="slotProps.data.documentProgramTopics[0].documentProgramTopicId" 
                        />
                    </template>
                </Column>
                
                <template #paginatorstart>
                    <Button 
                        type="button" 
                        icon="pi pi-refresh" 
                        class="p-button-text"
                        @click="obtainProgramsByTopicIdByCompany()" />
                </template>

            </DataTable>
        </div>
        <div v-else>
            <div class="row">
                <div class="col-12 py-4 d-flex justify-content-center">
                <ProgressSpinner />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProgressSpinner from 'primevue/progressspinner';
import DataTable from 'primevue/datatable';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Column from 'primevue/column';
import Checkbox from 'primevue/checkbox';
import Dropdown from 'primevue/dropdown';
// if we use Slot remove 
// import InputSwitch from 'primevue/inputswitch';

import { FilterMatchMode, FilterOperator } from 'primevue/api';
import formatToGlobalDate from '../../../../../../shared/helpers/date.helper';
import redirectHelperPage from '../../../../../../shared/helpers/redirectToHelperPage';
// import { useToast } from 'vue-toastification';
// const toast = useToast();

export default {
    name: 'CompanyDocumentProgramList',
    components: {
        ProgressSpinner,
        DataTable,
        Button,
        InputText,
        Column,
        Checkbox,
        Dropdown,
        // InputSwitch, // IF WE USE SLOT REMOVE THIS
    },
    props:['companyId'],
    data() {
        return {
            showCompany: false, 

            filters:null,
            loading: true,
            programCheck: [],
            //Sort
            sortFieldValue:null,
            sortOrderValue: null,
            // Pag
            firstPagValue: null,
            rowsPagValue: 5,
            //filters
            filterProgramStatus: null, 
        }
    },

    async created() {
        this.initFilters();
    },
    async mounted() {
        await this.getCountries();
        await this.obtainProgramStatus();
        await this.obtainProgramsByTopicIdByCompany();
        // await this.obtainProgramsByCompany();

        this.cleanDocumentProgramTopicIds();
        this.setTopicProgramSort();
    },
    beforeUnmount(){
        // console.log('destroy Program');
    },
    computed: {
        allProgramsFilteredByTopicByCompany() {
            let programs = this.$store.getters.obtainProgramsFilteredByTopicIdByCompany;
            if(programs){
                programs.map(program => {
                program.dateAdd = program.documentProgramTopics[0].programDate;
                program.status = this.getProgramStatusName(program.documentProgramTopics[0].documentProgramStatusId) ; 
                });
                programs.sort(function(a, b){
                    return new Date(b.dateAdd) - new Date(a.dateAdd)
                })
            }
            if(this.filterProgramStatus){
                if(this.filterProgramStatus === "Status not set"){
                    programs = programs.filter(program => program.status === "")
                }
                else{
                    programs = programs.filter(program => program.status === this.filterProgramStatus );
                }
            }

            return programs ;
        },
        allProgramsFilteredByCompany(){
            let programs = this.$store.getters.obtainProgramsFilteredByCompany; 
            if(programs){
                programs.map(program => {
                program.dateAdd = program.documentProgramTopics[0].programDate;
                program.status = this.getProgramStatusName(program.documentProgramTopics[0].documentProgramStatusId) ; 
                });
                programs.sort(function(a, b){
                    return new Date(b.dateAdd) - new Date(a.dateAdd)
                })
            }

            if(this.filterProgramStatus){
                if(this.filterProgramStatus === "Status not set"){
                    programs = programs.filter(program => program.status === "")
                }
                else{
                    programs = programs.filter(program => program.status === this.filterProgramStatus );
                }
            }

            //TODO SET GETTER FOR GET ALL PROGRAMSFILTEREDBYCOMPANY
            return programs
        },

        selectedTopic() {
            return this.$store.getters.obtainSelectedTopic;
        },
        countries(){
           return this.$store.getters.obtainCountries;
        },

        programStatus(){
            return  this.$store.getters.obtainProgramStatus;
        },
        programStatusOptions(){
            const status = this.$store.getters.obtainProgramStatus;
            const statusFIlterOptions = [
                {
                    label: 'Status',
                    items: status
                },
                {
                    label: null, 
                    items: [
                        {
                            "documentProgramStatusId": "StatusNotSet",
                            "status": "Status not set",
                        }
                    ]
                }

            ]
            return statusFIlterOptions
        },

        topicProgramSort(){
            const programSortTopic =this.$store.getters.obtainTopicProgramSort 
            
            try {
                if(this.selectedTopic.topicId === programSortTopic.topicId){
                    return programSortTopic
                }
                else{
                    const programSortFromTopic = {
                        sortField: null,
                        sortOrder: null,
                        firstPagValue: null,
                        rowsPagValue: null,

                        //filters
                        status: null,
                    }
                    return programSortFromTopic
                }
                
            } catch (error) {
                const programSortFromTopic = {
                    sortField: null,
                    sortOrder: null,
                    firstPagValue: null,
                    rowsPagValue: null,
                    //filters
                    status: null,
                }
                return programSortFromTopic
            }
            // return this.$store.getters.obtainTopicProgramSort;
        },
        
    },
    methods: {
        goToHeaderPage(section){
            redirectHelperPage(section);
        },
        async getCountries() {
            try {
                this.loading = true;
                await this.$store.dispatch('obtainCountries');
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },
        async obtainProgramsByTopicIdByCompany() {
            try {
                this.loading = true
                // TODO CHECK CODE WHEN BACKEND FINISH
                await this.$store.dispatch(
                    'obtainProgramsFilteredByTopicIdByCompany',
                    {companyId: this.companyId, topicId: this.selectedTopic.topicId,}
                )
                this.loading = false
            }
            catch (error) {
                // toast.error(error.message);
                this.$store.dispatch(
                    'resetProgramsFilteredByTopicIdByCompany'
                )
                this.loading = false
            }
        },
        // async obtainProgramsByCompany(){
        //     try {
        //         this.loading = true; 
        //         if(this.companyId){
        //             await this.$store.dispatch('obtainProgramsFilteredByCompany', {companyId: this.companyId})
        //         }
        //         this.loading = false; 
        //     } catch (error) {
        //         this.$store.dispatch('resetProgramsFilteredByCompany',)
        //         this.loading = false;
        //     }
        // }, 
        async obtainProgramStatus(){
            try {
                this.loading= true;
                await this.$store.dispatch('obtainProgramStatus');
                this.loading = false;
            } catch (error) {
                this.loading = false; 
            }
        },


        setTopicProgramSort(){
            try {
                //sort
                this.sortFieldValue = this.topicProgramSort.sortField;
                this.sortOrderValue = this.topicProgramSort.sortOrder;

                //pag
                if(this.topicProgramSort.firstPagValue === null){
                  this.firstPagValue = 0;
                }
                else{
                    this.firstPagValue = this.topicProgramSort.firstPagValue;
                }
                if(this.topicProgramSort.rowsPagValue === null){
                    this.rowsPagValue = 5;
                }
                else{
                    this.rowsPagValue = this.topicProgramSort.rowsPagValue
                }

                //filters 
                this.filterProgramStatus = this.topicProgramSort.status;

            } catch (error) {
                console.log(error);
            }
        },

        // SHOW IF PROGRAMS IS FROM COMPANY OR ALL PROGRAMS
        showIsProgramFilteredByCompany(){
            if(this.showCompany){
                return this.allProgramsFilteredByCompany;
            }else{
                return this.allProgramsFilteredByTopicByCompany;
            }
        },


        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                name: {
                    operator: FilterOperator.AND,
                    constraints: [
                        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                },
            };
        },
        clearFilter() {
            this.initFilters();
        },
        async openEditProgram(event) {
            this.$router.push(`/admin/topics/program/overview/${event.documentProgramTopics[0].documentProgramTopicId}`);
        },
        
        getCountryName( countryId ){
            const a = this.countries.find(country => country.countryId=== countryId)
            return a.name 
        },
        formatDate(date) {
          return formatToGlobalDate(date);
        },
        //get Status
        getProgramStatusName(programStatusId ){
            const statusName =  this.programStatus.filter(stat => stat.documentProgramStatusId === programStatusId);
            if(statusName[0]){
                return statusName[0].status
            }
            else{
                return ''
            }
        },
        

        // Multiple Programs 
        cleanDocumentProgramTopicIds(){
            this.$store.dispatch('cleanDocumentProgramTopicIds')
        },
        

        // sortFunctions
        onSort(e){
            const topicProgramSort = {
                sortField: e.sortField,
                sortOrder: e.sortOrder,
                firstPagValue: e.first,
                rowsPagValue: e.rows,
                topicId:this.selectedTopic.topicId
            }
            this.$store.dispatch('updateTopicProgramSort', topicProgramSort)
        },
        onChangeFilters(){
            const filterDocProgStatus = {
                topicId: this.selectedTopic.topicId,
                status: this.filterProgramStatus,
            }

            this.$store.dispatch('updateTopicProgramSortFilters', filterDocProgStatus);
        },

        //Open add Program 
        openCreateProgram(){
            this.$store.dispatch('updateCompanyForCreateProgram', this.companyId);
            this.$router.push('/admin/topics/program/new')
        },
    },
    watch: {

        filterProgramStatus(){
            this.onChangeFilters();
        }
    }
}
</script>

<style scoped>
.keywordSearch{
  /* padding: 0 0 0 2.5rem  !important; */
  padding: 0.2rem 0 0.2rem 2.5rem  !important;
}
</style>
